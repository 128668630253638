import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import BackPresence from "../../../static/svg/presence.svg"

const Hero = () => {
  const data = useStaticQuery(graphql` 
    query{
      file(name: {eq: "building"}) {
        childImageSharp {
          fluid (maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
      <Styled>
        <section>
          <div className="contenedor-background">
            <h2><strong>NUESTRA PRESENCIA</strong></h2>
            <h2><strong>+ de 11,000 </strong> colaboradores en</h2>
            <p>México</p>
            <p>Colombia</p>
            <p>Ecuador</p>
            <p>Perú</p>
            <p>Brasil</p>
            <p>Argentina</p>
            <p>USA</p>
          </div>
          <div className="contenedor-experiencia">
            <h2 className='numero'><strong>36</strong></h2>
            <h2 className='ano'><strong>AÑOS</strong></h2>
            <p>experiencia</p>
          </div>
          <div className="contenedor-presencia">
            <h2 className='numero'><strong>7</strong></h2>
            <h2 className='ano'><strong>PAÍSES</strong></h2>
            <p>presencia</p>
          </div>
          <div className="contenedor-alianza">
            <h2 className='numero'><strong>9</strong></h2>
            <h2 className='ano'><strong>ALIANZAS</strong></h2>
            <p>empresariales</p>
          </div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Hitss" />
        </section>
        <BackPresence className='backImage'/>
      </Styled>
  )
}

export default Hero

const Styled = styled.section`
.contenedor-background{
  position: absolute;
  z-index: 6;
  width: 120px;
  height:77px;
  right: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 270px){
    display:none;
  }

  @media screen and (max-width: 375px){
    right: -5.5rem;
    width: 200px;
  }
  
  @media screen and (min-width: 800px){
    right: 3rem;
    width: 200px;
  }
  @media screen and (min-width: 960px){
    padding-top: 2rem;
  }
  @media screen and (min-width: 1400px){
    padding-top: 3rem;
    right: 8rem;
    width: 350px;
  }
  
}
.contenedor-background h2{
  font-family: 'Century Gothic', sans-serif;
  font-size: 7px;
  margin: 0;
  line-height: 10px;
  letter-spacing: 0;
  
  @media screen and (min-width: 600px){
    font-size: 10px;
    line-height: 15px;
  }
  @media screen and (min-width: 800px){
    font-size: 15px;
    line-height: 20px;
  }
  @media screen and (min-width: 1400px){
    font-size: 22px;
    line-height: 32px;
  }
} 
.contenedor-background p{
  font-family: 'Century Gothic', sans-serif;
  font-size: 7px;
  margin: 0;
  
  @media screen and (min-width: 600px){
    font-size: 10px;
    line-height: 15px;
  }
  @media screen and (min-width: 800px){
    font-size: 15px;
    line-height: 20px;
  }
  @media screen and (min-width: 1400px){
    font-size: 22px;
    line-height: 32px;
  }
}   

.contenedor-experiencia{
  position: absolute;
  z-index: 6;
  width: 55px;
  height: 77px;
  right: 6.5rem;
  top: 11.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 375px){
    display:none;
  }

  
  @media screen and (min-width: 600px){
    right: 11.5rem;
    top: 17rem;
  }

  @media screen and (min-width: 800px){
    right: 15.5rem;
    top: 20rem;
  }

  @media screen and (min-width: 960px){
    right: 18rem;
    top: 25rem;
  }

  @media screen and (min-width: 1400px){
    right: 32rem;
    top: 35rem;
  }
}

.contenedor-presencia{
  position: absolute;
  z-index: 6;
  width: 55px;
  height: 77px;
  right: 3.2rem;
  top: 11.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 375px){
    display:none;
  }
  
  @media screen and (min-width: 600px){
    right: 6rem;
    top: 17rem;
  }
  @media screen and (min-width: 800px){
    right: 9rem;
    top: 20rem;
  }

  @media screen and (min-width: 960px){
    right: 10rem;
    top: 25rem;
  }
  @media screen and (min-width: 1400px){
    right: 18rem;
    top: 35rem;
  }
}

.contenedor-alianza{
  position: absolute;
  z-index: 6;
  width: 55px;
  height: 77px;
  right: 0rem;
  top: 11.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 375px){
    display:none;
  }

  @media screen and (min-width: 600px){
    right: 0.5rem;
    top: 17rem;
  }
  @media screen and (min-width: 800px){
    right: 2rem;
    top: 20rem;
  }

  @media screen and (min-width: 960px){
    right: 2rem;
    top: 25rem;
  }
  @media screen and (min-width: 1400px){
    right: 5rem;
    top: 35rem;
  }
}

strong{
  -webkit-text-stroke: 0.3px black;
  @media screen and (min-width: 1400px){
    -webkit-text-stroke: 1px black;
  }

}

.numero{
  font-size: 20px;
  font-family: 'Century Gothic', sans-serif;
  margin: 0px;
  font-weight: bold;
  
  @media screen and (min-width: 600px){
    font-size: 23px;
  }

  @media screen and (min-width: 800px){
    font-size: 40px;
  }
  @media screen and (min-width: 1400px){
    font-size: 60px;
  }
}

.ano{
  font-size: 7px;
  font-family: 'Century Gothic', sans-serif;
  margin: 0px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #000000;

  @media screen and (min-width: 600px){
    font-size: 10px;
  }
  @media screen and (min-width: 800px){
    font-size: 15px;
  }
  @media screen and (min-width: 1400px){
    font-size: 20px;
  }
}

p{
  font-size: 6px;
  font-family: 'Century Gothic', sans-serif;
  margin: 0px;
  @media screen and (min-width: 600px){
    font-size: 9px;
  }

  @media screen and (min-width: 800px){
    font-size: 14px;
  }

}
.backImage{
  position: absolute;
  top: 197px;
  height: auto;
  z-index: -1;
  width: 130px;
  right: 0;
  transition: all linear .5s;
  @media screen and (min-width: 600px){
    width: 200px;
    top: 338px;
  }
  @media screen and (min-width: 1100px){
    width: 350px;
  }
}

`